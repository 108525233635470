<template>
  <div class="newTask">
    <!-- top -->
      <el-breadcrumb separator="/">
          <el-breadcrumb-item>账户管理</el-breadcrumb-item>
          <el-breadcrumb-item @click.native="goback1">机构管理</el-breadcrumb-item>
          <el-breadcrumb-item>机构详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="center">
           <span class="blueTitle" style="margin-left:12px;">机构详情</span>
          <div class="create">
              <div class="title">
                <span class="left">机构信息</span>
                <span class="right">
                    <span class="save" slot="reference" @click="edit">编辑</span>
                </span>
              </div>
              <div class="selectBox">
                    <el-descriptions style="margin-left:24px">
                        <el-descriptions-item label="机构名称">{{orgDetailForm.orgName}}</el-descriptions-item>
                        <el-descriptions-item label="机构编号">{{orgDetailForm.orgCode}}</el-descriptions-item>
                        <el-descriptions-item label="机构类型">{{orgDetailForm.orgType}}</el-descriptions-item>
                        <el-descriptions-item label="机构所在地">{{orgDetailForm.orgProvinceName}}{{orgDetailForm.orgCityName}}</el-descriptions-item>
                        <el-descriptions-item label="机构负责人">{{orgDetailForm.orgContactsName}}</el-descriptions-item>
                        <el-descriptions-item label="负责人联系方式">{{orgDetailForm.orgContactsMobile}}</el-descriptions-item>
                        <el-descriptions-item label="机构邮箱">{{orgDetailForm.orgEmail}}</el-descriptions-item>
                        <el-descriptions-item label="机构地址">{{orgDetailForm.orgAddress}}</el-descriptions-item>
                    </el-descriptions>
                <div class="dll" @click="getAllCodeImg">下载所有二维码</div>
              </div>
          </div>
      </div>
     
      <div class="code">
          <div class="basecode">
            <el-tooltip class="item" effect="dark" content="点击下载" placement="top">
              <img class="code-imag" :src="codeImg1" @click="doload1"/>
            </el-tooltip>
            <span class="code-text">ai在线检测机构二维码</span>
          </div>
          <div class="basecode">
             <el-tooltip class="item" effect="dark" content="点击下载" placement="top">
              <img class="code-imag" :src="codeImg2" @click="doload2"/>
            </el-tooltip>
            <span class="code-text">社区筛查检测机构二维码</span>
          </div>
          <div class="basecode">
             <el-tooltip class="item" effect="dark" content="点击下载" placement="top">
              <img class="code-imag" id="canvas" :src="codeImg3" @click="doload3"/>
            </el-tooltip>
            <span class="code-text">检测任务二维码</span>
          </div>
          
      </div>
      
      <!-- 编辑弹窗 -->
        <el-dialog title="修改机构信息" width="920px" :visible.sync="editOrgDetail">
                <el-form :model="orgUpdateForm" :inline="true" :rules="rules" ref="orgUpdateForm">
                        <el-form-item label="机构名称" prop="name" style="margin-left:20px">
                            <el-input v-model="orgUpdateForm.name" autocomplete="off" style="width:160px;"></el-input>
                        </el-form-item>
                        <el-form-item label="机构类型" prop="type" style="margin-left:20px">
                            <el-select v-model="orgUpdateForm.type" placeholder="请选择活动区域" style="width:160px;">
                                <el-option label="检测机构" value="检测机构"></el-option>
                                <el-option label="受检机构" value="受检机构"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="机构邮箱" prop="email" style="margin-left:20px">
                            <el-input v-model="orgUpdateForm.email" autocomplete="off" style="width:160px;"></el-input>
                        </el-form-item>
                         <el-form-item label="机构地址" prop="address" style="margin-left:20px">
                            <el-input v-model="orgUpdateForm.address" autocomplete="off" style="width:160px;"></el-input>
                        </el-form-item>
                        <el-form-item label="机构所在地" prop="city" style="margin-left:20px">
                            <el-cascader v-model="orgUpdateForm.city" :options="district" clearable style="width:160px !important;"></el-cascader>
                        </el-form-item>
                        <el-form-item label="机构负责人" prop="host" style="margin-left:20px">
                            <el-input v-model="orgUpdateForm.host" autocomplete="off" style="width:160px;"></el-input>
                        </el-form-item>
                        <el-form-item label="负责人联系方式" prop="mobile" style="margin-left:20px">
                            <el-input v-model="orgUpdateForm.mobile" autocomplete="off" style="width:160px;"></el-input>
                        </el-form-item>
                </el-form>
                <!-- footer -->
                
                <div class="footerSub">
                    <div class="tijiao" @click="cancel">取消</div>
                    <div class="tijiao" @click="save('orgUpdateForm')">保存</div>
                </div>
        </el-dialog>
  </div>
</template>
<script>
import { district } from '@/assets/js/cityCode.js'
import {viewOrg,updateOrg,viewOrgCode1,viewOrgCode2} from '@/api/orgController.js'
import QRCode from 'qrcode'
import JSZip from 'jszip'
import FileSaver from 'file-saver'  //不能删
export default {
  beforeRouteLeave(to,from,next){
    if(to.name === 'OrgController'){
      to.meta.keepAlive = true;
    }
    next();
  },
    data(){
        //自定义校验规则
        var checkMobile = (rule, value, callback) => {
            let reg =/^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
                if (!value) {
                    callback(new Error('手机号不能为空'));
                } else {
                    if (!reg.test(value)) {
                        callback(new Error('手机号码格式不正确'));
                    }
                        callback();
                    }
            };
      return{
        //cityCode
        district,
        //任务详情form
        orgDetailForm:{
          orgCode:null,
          orgName:null,
          orgLogo:null,
          orgProvinceName:null,
          orgProvinceCode:null,
          orgCityName:null,
          orgCityCode:null,
          orgEmail:null,
          orgContactsMobile:null,
          orgContactsName:null,
          orgType:null,
          orgAddress:null
        },
        //taskID
        orgId:null,
        // 编辑表单弹窗
        editOrgDetail:false,
        //更新编辑信息
        orgUpdateForm:{
            name:null,
            type:null,
            city:null,
            host:null,
            mobile:null,
            email:null,
            address:null,
        },
        //校验规则
        rules:{
             name: [ { required: true, message: '请填写机构名', trigger: 'blur' }],
             type: [ { required: true, message: '请选择机构类型', trigger: 'blur' }],
             email:[{ type: 'email', required: false, message: '请正确填写邮箱', trigger: 'blur' }],
             address:[ { required: true, message: '请填写地址', trigger: 'blur' }],
             city:[ { required: true, message: '请选择城市', trigger: 'blur' }],
             host:[ { required: true, message: '请填写机构负责人', trigger: 'blur' }],
             mobile:[ { required: true, validator: checkMobile, message: '请正确填写手机号码', trigger: 'blur' }],
        },
        //机构编号
        orgNo:null,
        //二维码图片
        codeImg1:null,
        codeImg2:null,
        codeImg3:null,
        //base64
        base64Img1:null,
        base64Img2:null,
        base64Img3:null,
      }
    },
    created(){
      if(this.$route.query.id){
        this.orgId = this.$route.query.id;
        //任务详情
        this.viewById();
        this.getCode1();
        this.getCode2();
         
      }
    },
    methods:{
      //面包屑返回
      goback1(){
            this.$router.go(-1);
        },
    //具体id查询
        viewById(){
            viewOrg({
                id:this.orgId
            }).then(res=>{
                if(res.data.status == 200){
                    this.orgDetailForm = res.data.data;
                    this.orgNo = res.data.data.orgCode;
                    this.getCode3(this.orgNo);
                    this.getOldInfo();//编辑时信息回调
                }else{
                  this.$message.error(res.data.message);
                }
            }).catch(res=>{
               this.$message.error(res);
            })
        },
    // 编辑
      edit(){
        this.editOrgDetail = true ;
      },
    //取消编辑
    cancel(){
        //clear
        for(var el in this.orgUpdateForm) {
            this.orgUpdateForm[el] = "";
        }
        this.editOrgDetail = false;
    },
    //确认保存编辑
    save(formName){
         this.$refs[formName].validate((valid) => {
          if (valid) {
            updateOrg({
                id:this.orgId,
                orgName:this.orgUpdateForm.name,
                orgType:this.orgUpdateForm.type,
                orgCity:this.orgUpdateForm.city[1],
                orgProvince:this.orgUpdateForm.city[0],
                orgContactsName:this.orgUpdateFormhost,
                orgContactsMobile:this.orgUpdateForm.mobile,
                orgEmail:this.orgUpdateForm.email,
                orgAddress:this.orgUpdateForm.address,
            }).then(res=>{
                if(res.data.status == 200){
                    this.$message({
                    message: '创建任务成功！',
                    type: 'success'
                    });
                //更新当前列表
                this.viewById();
                this.editOrgDetail = false;
                }else{
                  this.$message.error(res.data.message);
                }
            }).catch(res=>{
               this.$message.error(res);
            })
          } else {
            this.$message.error("请正确填写相应内容！");
            return false;
          }
        });
    },
    //编辑时信息回调
      getOldInfo(){
        this.orgUpdateForm.name = this.orgDetailForm.orgName;
        this.orgUpdateForm.type = this.orgDetailForm.orgType;
        this.orgUpdateForm.city = [this.orgDetailForm.orgProvinceCode,this.orgDetailForm.orgCityCode];
        this.orgUpdateForm.host = this.orgDetailForm.orgContactsName;
        this.orgUpdateForm.mobile = this.orgDetailForm.orgContactsMobile;
        this.orgUpdateForm.email = this.orgDetailForm.orgEmail;
        this.orgUpdateForm.address = this.orgDetailForm.orgAddress;
      },
    //获取二维码信息 
    //ai在线检测二维码
    getCode1(){
      viewOrgCode1({
        id:this.orgId
      }).then(res=>{
        this.base64Img1 = res.data;
        this.codeImg1 = window.URL.createObjectURL(res.data)
      })
    },
    //社区筛查检测机构二维码
    getCode2(){
      viewOrgCode2({
        id:this.orgId
      }).then(res=>{
        this.base64Img2 = res.data;
        this.codeImg2 = window.URL.createObjectURL(res.data)
  
      })
    },
    //ai在线检测二维码
    getCode3(qrdata){
      var url = this.qrcode(qrdata);
      this.codeImg3 = url;
      let base64 = {
              dataURL: url,//用url方式表示的base64图片数据
              type: 'image/jpeg'//文件类型
            }
      this.base64Img3 = this.convertBase64UrlToBlob(base64);
    },
    //生成二维码
      qrcode(qrdata){
        var URL =null;
          QRCode.toDataURL(
            qrdata, 
            { errorCorrectionLevel: 'L', margin: 2, width: 128 },
              (err, url) => {
              if (err) throw err;
              URL = url
            }
            )
            return URL
        },
    //点击下载二维码
    doload1(){
      var a = document.createElement('a')
              a.download = 'ai在线检测机构二维码'
              // 设置图片地址
              a.href = this.codeImg1;
              a.click();
    },
    doload2(){
      var a = document.createElement('a')
              a.download = '社区筛查检测机构二维码'
              // 设置图片地址
              a.href = this.codeImg2;
              a.click();
    },
    doload3(){
      var a = document.createElement('a')
              a.download = '检测任务二维码'
              // 设置图片地址
              a.href = this.codeImg3;
              a.click();
    },
    //base64转blob
      convertBase64UrlToBlob(base64) {
            let urlData = base64.dataURL
            let type = base64.type
            let bytes=null
            if(urlData.split(',').length>1){//是否带前缀
            bytes = window.atob(urlData.split(',')[1]) // 去掉url的头，并转换为byte
            }else{
            bytes = window.atob(urlData) 
            }
              // 处理异常,将ascii码小于0的转换为大于0
            let ab = new ArrayBuffer(bytes.length)
            let ia = new Uint8Array(ab)
            for (let i = 0; i < bytes.length; i++) {
              ia[i] = bytes.charCodeAt(i)
            }
            return new Blob([ab], { type: type })
      },
    //一键导出为二维码
    getAllCodeImg(){
      const arrImages = [{name:'ai在线检测机构二维码',url:this.base64Img1},{name:'社区筛查检测机构二维码',url:this.base64Img2},{name:'检测任务二维码',url:this.base64Img3}];
        const zip = new JSZip();
        arrImages.forEach((item)=>{
          zip.file(item.name+'.png', item.url, {base64: true});
        })
        zip.generateAsync({type:"blob"}).then(function(content) {
          saveAs(content, "codeImg.zip");
        });
      },
    }
}
</script>
<style lang="stylus">
//change elemen-ui style
@import '~@/assets/css/newTask.css'

//comstyle 
.blueTitle
  font-size: 16px 
  color: #353535
  line-height: 28px 
  font-weight: bold
  position: relative
  &::before
    width: 3px
    height: 18px
    content: ""
    position: absolute
    border-radius: .03rem
    left: -10px
    bottom:1px
    background-color: #00CCCC
.center
  width: 100%
  overflow hidden
  margin-top: 24px 
  .create
    width: 100% 
    position:relative
    margin-top: 24px
    .title
      width: 100%
      height: 32px 
      background-color: #00cccc
      display: flex
      justify-content: space-between
      align-items: center
      .left 
        margin-left: 20px
        font-family: Source Han Sans CN
        font-size: 16px
        color: #fff
      .right
        margin-right: 20px 
        .cancel 
          margin-right: 16px 
          font-family: Source Han Sans CN
          font-size: 16px
          color: #fff
          cursor pointer
        .save
          font-family: Source Han Sans CN
          font-size: 16px
          cursor pointer
          color: #fff
    .selectBox
      width: 100%
      padding: 24px 0
      position relative
      box-sizing: border-box
      background-color: #fff 


/*二维码*/
.code 
  width: 100% 
  display: flex
  padding: 60px 0
  background-color: #fff
  justify-content: space-evenly
  .basecode 
    width: 240px  
    text-align: center
    .code-imag
      width: 240px  
      height: 240px
      cursor pointer
    .code-text
      font-size: 20px

.dll
  width: 120px 
  height: 30px 
  position: absolute
  bottom:4px 
  right: 20px
  line-height: 30px
  text-align: center
  color: #fff
  cursor pointer
  background-color: #00cccc



.footerSub
    width: 400px
    margin: 0 auto
    display: flex
    justify-content: space-evenly
    .tijiao
        width:80px
        height:32px
        font-size:16px
        color:#fff;cursor:pointer
        background-color: #00cccc
        line-height: 32px
        border-radius:4px
        text-align: center
    


</style>